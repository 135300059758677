<template>
  <router-view v-slot="{ Component }" v-if="!isLoadingUser && !isIframe">
    <transition name="fade" mode="out-in" appear>
      <component :is="Component" />
    </transition>
  </router-view>

  <DeleteConfirmModal v-if="!isIframe" />
  <teleport to="body">
    <Notification v-if="!isIframe" />
  </teleport>
  <ConfirmModal v-if="!isIframe" />

  <Loading
    v-if="!isIframe"
    v-model:active="isLoadingUser"
    :can-cancel="false"
    :is-full-page="true"
    color="#0C1E67"
    loader="dots"
    :opacity="0"
  />

  <Loading
    v-if="!isIframe"
    v-model:active="isLoadingContent"
    :can-cancel="false"
    :is-full-page="true"
    color="#0C1E67"
    loader="dots"
    :opacity="0.7"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import sharedData from "@/mixins/sharedData";
import Notification from "@/layouts/Notification.vue";
import Loading from "vue-loading-overlay";
import DeleteConfirmModal from "@/components/Modals/DeleteConfirmModal.vue";
import ConfirmModal from "@/components/Patient/Modal/ConfirmModal.vue";

export default {
  name: "App",
  components: {
    Notification,
    Loading,
    DeleteConfirmModal,
    ConfirmModal,
  },
  mixins: [sharedData],
  computed: {
    ...mapGetters({
      isLoadingUser: "loader/getUserLoaderStatus",
      isLoadingContent: "loader/getContentLoaderStatus",
    }),
  },
  methods: {
    ...mapActions({
      initCurrentLanguage: "language/initCurrentLanguage",
      fetchFeatures: "features/fetchFeatures",
    }),
  },
  created() {
    this.initCurrentLanguage();
    if (!this.isIframe) {
      this.fetchFeatures();
    } else {
      this.$gtm.enable(false);
    }
  },
  mounted() {
    if (this.$route.query._gl) {
      this.$router.replace({
        ...this.$router.currentRoute,
        query: {
          _gl: undefined,
        },
      });
    }
  },
};
</script>
