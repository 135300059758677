export default {
  namespaced: true,
  state: {
    modals: [
      { name: "changePassword", isOpen: false },
      { name: "updateUserData", isOpen: false },
      { name: "addPatientModal", isOpen: false },
      { name: "addChildrenModal", isOpen: false },
      { name: "editPatientModal", isOpen: false },
      { name: "authCodeModal", isOpen: false },
      { name: "authCreateSuccessModal", isOpen: false },
      { name: "checkoutCardModal", isOpen: false },
      { name: "dateOfTheVisitModal", isOpen: false },
      { name: "addCurrentPatientModal", isOpen: false },
      { name: "logInAlertModal", isOpen: false },
      { name: "deleteConfirmModal", isOpen: false },
      { name: "addAddressModal", isOpen: false },
      { name: "addInvoiceDataModal", isOpen: false },
      { name: "savedAddressesModal", isOpen: false },
      { name: "editAddressModal", isOpen: false },
      { name: "editInvoiceDataModal", isOpen: false },
      { name: "savedInvoicesDataModal", isOpen: false },
      { name: "afterPaymentModal", isOpen: false },
      { name: "confirmModal", isOpen: false },
      { name: "askInvoiceModal", isOpen: false },
      { name: "consentDoctorsModal", isOpen: false },
      { name: "blockPatientModal", isOpen: false },
      { name: "changePhoneNumberModal", isOpen: false },
      { name: "addInvoiceModal", isOpen: false },
      { name: "whereToFindBarcode", isOpen: false },
      { name: "singleReferralResultsModal", isOpen: false },
      { name: "notFoundReferralModal", isOpen: false },
      { name: "chooseFacilityModal", isOpen: false },
      { name: "authModal", isOpen: false },
      { name: "codeInputModal", isOpen: false },
      { name: "loginAgeExceptionModal", isOpen: false },
      { name: "FirstLoginAttemptModal", isOpen: false },
      { name: "authToOldSystemModal", isOpen: false },
      { name: "userExistInOldSystemExceptionModal", isOpen: false },
      { name: "unableToPickHomeServiceForKidModal", isOpen: false },
      { name: "patientsWithNoExamsAssigned", isOpen: false },
    ],
  },
  getters: {
    isOpenModal: (state) => (name) => {
      return state.modals.find((modal) => modal.name === name).isOpen;
    },
    getModalData: (state) => (name) => {
      return state.modals.find((modal) => modal.name === name);
    },
  },
  mutations: {
    closeModal(state, name) {
      const index = state.modals.findIndex((modal) => modal.name === name);
      state.modals[index] = { name: state.modals[index].name, isOpen: false };
    },
    openModal(state, name) {
      const currentModal = state.modals.find((modal) => modal.name === name);
      currentModal.isOpen = true;
    },
    openModalCallback(state, payload) {
      const defaultCallbacks = { onConfirm: () => {}, onCancel: () => {} };
      const index = state.modals.findIndex(
        (modal) => modal.name === payload.name
      );

      state.modals[index] = {
        ...state.modals[index],
        ...defaultCallbacks,
        ...payload,
        isOpen: true,
      };
    },
  },
};
